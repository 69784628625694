@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}


body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
@font-face{
  font-family: "oswald-medium";
  src: url('../src/Oswald-Medium.ttf');
}
@font-face {
  font-family: "burtons";
  src: url('../src/Burtons.otf');
  
}
@font-face {
  font-family: "poppins";
  src: url('../src/Poppins-Regular.ttf');
}
@font-face {
  font-family: "poppins-semi";
  src: url('../src/Poppins-SemiBold.ttf');
  }
  @font-face {
    font-family: "oswald-semi";
    src: url('../src/Oswald-SemiBold.ttf');
  }